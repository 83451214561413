@import "./colors.scss";
@import "./mixins.scss";

.container {
  display: grid;
  grid-template-columns: 192px 360px auto;
  padding: 0px;
  height: 100vh;
  width: 100%;
  @include mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-right: solid 1px $orange;
  }
}

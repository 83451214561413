@import "./colors.scss";
@import "./fonts.scss";

.wrapper {
height:100%;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
overflow: auto;
}


.AboutButton {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 16px;
  position: fixed;
  bottom: 4px;
  right: 4px;
  z-index: 999;
  padding: 4px 10px;
align-items: center;
border-radius: 40px;
background: $orange;
color: $yellow;
box-shadow: none;
border:none;
&:hover {
  background: $blue;
}
}
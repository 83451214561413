@import '../colors.scss';
@import '../fonts.scss';
@import '../mixins.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  background-color: rgba($yellow, 0.8);
  background-blend-mode: lighten;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1000;
  padding: 24px;
}

.modalContent {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  border: 1px solid $orange;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  .content {
    padding: 0;
  }

  .modalHeader {
    width: 100%;
    border-bottom: 1px solid $orange;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .modalTitle {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      line-height: 24px;
      margin-left: 16px;
      font-weight: 700;
      color: $orange;
    }
    button {
      border: none;
      background-color: transparent;
      padding: 0px;
      margin-right: 10pt;
      display: flex;
      align-items: center;
    }
  }
}

@import '../colors.scss';
@import '../mixins.scss';

.moodWindow {
  /* Set the desired width and height for your section */
  width: 100%; /* Adjust this value to fit your layout */

  /* Set the animated WebP file as the background */
  // background-image: url("https://firebasestorage.googleapis.com/v0/b/moodpalace-f6317.appspot.com/o/bg-vids%2Ftumblr_0e317fae7a316f658380d7657b9c5d29_ee99f904_500.webp?alt=media&token=2d40ecd1-9b49-4bb8-aea9-19cf3123df79");
  background-size: cover;
  background-color: $blue;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: color-burn;
}

.MoodWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-blend-mode: exclusion;
  @include mobile {
    height: 240px;
    width: auto;
    border-bottom: 1px solid $orange;
  }

  video {
    /** Simulationg background-size: cover */
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }
}

.trail {
  position: absolute;
  background: #ff00ff;
  height: 2px; /* or whatever thickness you want for the trail */
  will-change: transform;
}

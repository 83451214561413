/* AudioPlayer.module.scss */
@import "../colors.scss";
@import "../fonts.scss";
@import "../mixins.scss";

:root {
  --plyr-audio-controls-background: transparent;
  --plyr-audio-control-color: $yellow;
  --plyr-control-padding: 4px;
  --plyr-control-spacing: 0px;
  --plyr-audio-control-background-hover: transparent;
  --plyr-audio-control-color-hover: $yellow;
}

.Player {
  position: sticky;
  top: 0px;
  @include mobile {
    margin: auto;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: -24px;
    top: unset;
    // width: calc(100% - 32px);
    width: 100%;
    z-index: 99;
    }
}

.time {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.65px;
}

.playerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0px;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Krona One", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: -0.6px;
    margin: 0px;
  }
}

.playerInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top:16px;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.playercontainer {
  background-color: $orange;
  color: $yellow;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mobile {
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.70), 0px 0px 2px 0px rgba(0, 0, 0, 0.90);
  }
  button {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }
}

@import '../colors.scss';
@import '../fonts.scss';
@import '../mixins.scss';

.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  flex: 1 0 0;
  align-self: normal;
  @include mobile {
    justify-content: flex-start;
    border-bottom: 1px solid $orange;
  }
  button {
    padding: 0;
    border: none;
    background: transparent;
    margin: 0;
    text-align: left;
  }

  .title {
    animation-name: colorchange;

    animation-duration: 20s;

    animation-iteration-count: infinite;

    animation-timing-function: ease-in-out;
    font-family: 'Krona One', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -2.4px;
    margin-left: -80px;
    transform: rotate(90deg);
    margin-top: 80px;
    margin-bottom: 0px;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: $orange;
    text-shadow: -0.5px -0.5px 0 $orange, 0.5px -0.5px 0 $orange,
      -0.5px 0.5px 0 $orange, 0.5px 0.5px 0 $orange;
    @include mobile {
      transform: none;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 120% */
      letter-spacing: -1.2px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
  .blurb {
    color: $orange;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.48px;
    margin: 0;
    a {
      color: $orange;
      background-color: transparent;
      border-radius: 8px;
      &:hover {
        background-color: darken($color: $blue, $amount: 10);
      }
    }
    @include mobile {
      margin-top: 8px;
    }
  }
}

@keyframes colorchange {
  0% {
    color: #fbcfe8;
  }
  33% {
    color: #b45309;
  }
  66% {
    color: #fde68a;
  }
  100% {
    color: #fbcfe8;
  }
}

.color-changing-text {
  animation-name: colorchange;
  /* the duration of the animation */
  animation-duration: 4s;
  /* the animation will play indefinitely */
  animation-iteration-count: infinite;
  /* ease to make the transitions smooth */
  animation-timing-function: ease-in-out;
}

.tooltipContainer {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.tooltip {
  visibility: hidden;
  min-width: max-content;
  width: auto;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: -0.4px;
  background-color: #fde68a;
  color: #b45309;
  text-align: center;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  z-index: 1000;

  box-shadow: 0px 0px 100px 40px rgba(#fde68a, 0.5),
    0px 0px 100px 100px rgba(#fde68a, 0.25);
  // bottom: 125%;
  // left: 50%;
  // margin-left: -40px;
}

.tooltipContainer:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

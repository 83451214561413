@import url('https://fonts.googleapis.com/css2?family=Inter&family=Krona+One&display=swap');
@import './mixins.scss';
@import './colors.scss';

* {
  transition: background-color 0.2s ease-in-out;
}
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  @include mobile {
    overflow: auto;
    overflow-x: hidden;
  }
}

html {
  background-color: #bfdbfe;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plyr {
  min-width: 0px !important; /* or whatever value you need */
  margin-right: 8px;
}

ul {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 0px;
  padding: 0px;
  li {
    margin: 0px;
  }
}

button {
  margin: 0px;
  cursor: pointer;
}

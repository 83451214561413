@import '../colors.scss';
@import '../fonts.scss';
@import '../mixins.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: pink; // Optional: background color
  height: 100vh;
  overflow-y: hidden;

  input[type='password'] {
    width: 80vw; // 80% of the viewport width
    padding: 24px; // Padding for better aesthetics
    font-size: 2rem; // Larger font size for readability
    margin: 10px 0; // Margin for spacing
    border: none;
    border-radius: 4px; // Rounded corners
    color: darken($color: $blue, $amount: 20);
    animation: fadeIn 2s ease-in-out;
    background-color: rgba(
      255,
      255,
      255,
      0.5
    ); // Slightly transparent background
    &::placeholder {
      color: darken($color: $blue, $amount: 20);
    }
    &:focus {
      outline: 1px;
      border-color: $blue; // Border color on focus
      background-color: rgba(255, 255, 255, 0.9); // Less transparent on focus
    }
  }
}

@import "../colors.scss";
@import "../fonts.scss";
@import "..//mixins.scss";


.container{
    display: flex;
    flex-direction: column;
    .copy {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 24px;
        padding: 24px;
        color: $orange;
        border-top: 1px solid $orange;
        a {
            color: $orange;
            background-color: transparent;
            border-radius: 8px;
            &:hover {
                background-color: darken($color: $blue, $amount:10);
            }
        }
    }
}
.AboutVideo {
    width: 100%;
    height: auto;
}


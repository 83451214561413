@import '../colors.scss';
@import '../fonts.scss';
@import '../mixins.scss';

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.container {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/moodpalace-f6317.appspot.com/o/bg-vids%2Fstatic.gif?alt=media&token=ec28f994-fde7-4e48-8718-5bcb7d56b3bb');
  background-size: cover;
  background-color: $blue;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: color-burn;
  overflow-y: scroll;
  overflow-x: hidden;
  border-left: 1px solid $orange;
  border-right: 1px solid $orange;
  position: relative;
  scrollbar-color: $blue $orange;

  @include mobile {
    border-left: none;
    border-right: none;
    overflow-y: auto;
    overflow-x: hidden;
    animation-name: slideInUp;
  }
}

.AudioFileList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: none;
  margin-bottom: 64px;
  @include mobile {
    margin-bottom: 176px;
  }
  button {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    font-family: 'Krona One', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: #b45309;
    text-transform: capitalize;
    text-align: left;
  }
}

.ListItem__Base {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 22px;
  border-bottom: 1px solid $orange;
  margin: 0px;
  position: relative;
  .NewLabel {
    display: flex;
    transform: rotate(-9.624deg);
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    position: absolute;
    left: 4px;
    top: 6px;
    border-radius: 26px;
    border: $pink700;
    background: $pink200;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    color: $pink700;
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }
}

.ListItem_IsPlaying {
  @extend .ListItem__Base;
  background-color: $yellow;
  &:hover {
    background-color: darken($yellow, 10);
  }
}

.ListItem {
  @extend .ListItem__Base;
  background-color: $blue;
  &:hover {
    background-color: darken($blue, 2);
  }
}

.instructions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 22px;
  background-color: $orange;
  .choose {
    color: $yellow;
    font-family: 'Krona One', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 184.615% */
    letter-spacing: -0.39px;
  }
}

.SongName {
  margin-left: 4px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: normal;
  }
}

.unmounted {
  height: 100%;
  width: 100%;
  padding: 24px;
  .copy {
    color: $blue;
    margin: auto auto;
    font-family: 'Inter', sans-serif;
    font-size: 240px;
    line-height: 240px;
    font-weight: 700;
    word-wrap: break-word;
  }
}

.Player {
  z-index: 99;
  position: sticky;
  top: 0px;
  background-color: $orange;
  color: $yellow;
  @include mobile {
    margin: auto;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    top: unset;
    width: calc(100% - 32px);
  }
  audio {
    width: 100%;
    border: none;
    padding-bottom: 16px;
    &::-webkit-media-controls-enclosure {
      background: transparent;
      border-radius: 0px;
      max-height: 64px;
      justify-content: flex-start;
    }
    &::-webkit-media-controls-panel {
      background-color: $orange;
      border: none;
      padding: 0 16px 0 0;
      justify-content: flex-start;
    }
    &::-webkit-media-controls-play-button {
      background-color: #b45309;
      border-radius: 100px;
      margin: 0 0 0 -8px;
      background-size: 24px;
      background-blend-mode: overlay;
    }
    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
      color: $yellow;
      text-shadow: none;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
    &::-webkit-media-controls-timeline {
      background-color: #b45309;
    }
    &::-webkit-media-controls-mute-button {
      background-color: #b45309;
      background-blend-mode: overlay;
    }
    &::-internal-media-controls-overflow-button {
      background-color: #b45309;
      background-blend-mode: overlay;
    }
  }
  audio::-webkit-media-controls.state-playing
    input[pseudo='-webkit-media-controls-play-button' i] {
    background-image: -internal-light-dark(
      url(../../public/icons/pause.svg),
      url(../../public/icons/play.svg)
    );
  }

  button {
    background-color: transparent;
    border: none;
  }
}

.time {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.65px;
}

.playerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  p {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    text-transform: uppercase;
  }
  h3 {
    font-family: 'Krona One', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: -0.6px;
    margin: 0;
  }
}

.playerInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.playercontainer {
  background-color: $orange;
  color: $yellow;
  padding: 16px 24px;
  height: 104px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mobile {
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7),
      0px 0px 2px 0px rgba(0, 0, 0, 0.9);
  }
  button {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }
}
